.Devices {
    display: flex;
}

.Devices .DeviceIcons {
    margin-right: 5px;
}

.Devices .DeviceIcons img:nth-of-type(2) {
    margin-left: 3px;
}
