body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.AdminButton {
  height: 30px;
  line-height: 30px;
  width: 200px;
  margin: 20px;
  background-color: #5297E0;
  color: white;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}
.UserCell {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.AppDetailPage {
  margin: 8px;
}

.AppDetailPage .AppIcon {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 8px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.AppDetailPage .Title {
  color: #4B5966;
  font-weight: 500;
  font-size: 24px;
}

.AppDetailPage .AppDetailCellContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.AppDetailPage .CenterContent {
  font-size: 14px;
  color: #99A0A6;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: -4px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.AppDetailPage .OwnershipButton {
  box-sizing: border-box;
  margin-top: 8px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #4B5966;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  color: black;
}

.AppDetailPage .OwnershipButton.Purchased {
  letter-spacing: 0.32px;
  background-color: #5297E0;
  color: white;
  border: unset;
}

.AppDetailPage .CenterContent .Title,
.AppDetailPage .CenterContent .OwnershipButton {
  display: none;
}

.AppDetailPage .CenterContent .OwnershipButton {
  width: 150px;
  margin: 8px 0;
}

@media (min-width: 768px) {
  .AppDetailPage .AppIcon {
      margin-right: 12px;
      width: 128px;
      height: 128px;
  }

  .AppDetailPage .OwnershipButton,
  .AppDetailPage > .Title {
      display: none;
  }

  .AppDetailPage .CenterContent .Title,
  .AppDetailPage .CenterContent .OwnershipButton {
      display: unset;
  }
}

.Ratings {
    display: -webkit-flex;
    display: flex;
}

.Ratings .RatingCount {
    margin-left: 4px;
}

.Devices {
    display: -webkit-flex;
    display: flex;
}

.Devices .DeviceIcons {
    margin-right: 5px;
}

.Devices .DeviceIcons img:nth-of-type(2) {
    margin-left: 3px;
}

.Price {
    text-align: center;
    font-size: 20px;
    color: #4B5966;
}

.Price.CollapseDownload {
    text-align: right;
    font-size: 16px;
}

.Price.CollapseDownload .Amount {
    font-weight: 500;
}

.Price > .PreviousPriceContainer {
    display: inline-block;
    font-size: 10px;
    color: red;
    margin-left: 4px;
}

.Price.CollapseDownload > .PreviousPriceContainer {
    display: -webkit-flex;
    display: flex;
    font-size: 12px;
    margin-left: unset;
}

.Price.CollapseDownload > .PreviousPriceContainer > .SaleLabel {
    display: none;
}

.Price > .PreviousPriceContainer > .PreviousPrice {
    text-decoration: line-through;
}

.Price.CollapseDownload > .PreviousPriceContainer > .PreviousPrice {
    margin-left: 4px;
}

.Price.CollapseDownload > .DownloadIcon {
    display: none;
}

.Price > .DownloadIcon img {
    width: 100px;
}

.Price.CollapseDownload > .DownloadIcon img {
    width: unset;
}

@media (min-width: 768px) {
    .Price,
    .Price.CollapseDownload {
        font-size: 24px;
        text-align: center;
        padding-left: 20px;
    }

    .Price > .DownloadIcon {
        padding-top: 8px;
    }

    .Price.CollapseDownload > .DownloadIcon {
        display: block;
    }

    .Price > .PreviousPriceContainer,
    .Price.CollapseDownload > .PreviousPriceContainer {
        margin-left: 8px;
    }

    .Price.CollapseDownload > .PreviousPriceContainer {
        display: inline-block;
    }

    .Price.CollapseDownload > .PreviousPriceContainer > .SaleLabel {
        display: unset;
    }

    .Price.CollapseDownload > .PreviousPriceContainer > .PreviousPrice {
        margin-left: 0;
    }
}

.PublishApps .AppContainer {
  border: 1px solid black;
  margin-bottom: 20px;
}
