.Price {
    text-align: center;
    font-size: 20px;
    color: #4B5966;
}

.Price.CollapseDownload {
    text-align: right;
    font-size: 16px;
}

.Price.CollapseDownload .Amount {
    font-weight: 500;
}

.Price > .PreviousPriceContainer {
    display: inline-block;
    font-size: 10px;
    color: red;
    margin-left: 4px;
}

.Price.CollapseDownload > .PreviousPriceContainer {
    display: flex;
    font-size: 12px;
    margin-left: unset;
}

.Price.CollapseDownload > .PreviousPriceContainer > .SaleLabel {
    display: none;
}

.Price > .PreviousPriceContainer > .PreviousPrice {
    text-decoration: line-through;
}

.Price.CollapseDownload > .PreviousPriceContainer > .PreviousPrice {
    margin-left: 4px;
}

.Price.CollapseDownload > .DownloadIcon {
    display: none;
}

.Price > .DownloadIcon img {
    width: 100px;
}

.Price.CollapseDownload > .DownloadIcon img {
    width: unset;
}

@media (min-width: 768px) {
    .Price,
    .Price.CollapseDownload {
        font-size: 24px;
        text-align: center;
        padding-left: 20px;
    }

    .Price > .DownloadIcon {
        padding-top: 8px;
    }

    .Price.CollapseDownload > .DownloadIcon {
        display: block;
    }

    .Price > .PreviousPriceContainer,
    .Price.CollapseDownload > .PreviousPriceContainer {
        margin-left: 8px;
    }

    .Price.CollapseDownload > .PreviousPriceContainer {
        display: inline-block;
    }

    .Price.CollapseDownload > .PreviousPriceContainer > .SaleLabel {
        display: unset;
    }

    .Price.CollapseDownload > .PreviousPriceContainer > .PreviousPrice {
        margin-left: 0;
    }
}
