.AppDetailPage {
  margin: 8px;
}

.AppDetailPage .AppIcon {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 8px;
  flex-shrink: 0;
}

.AppDetailPage .Title {
  color: #4B5966;
  font-weight: 500;
  font-size: 24px;
}

.AppDetailPage .AppDetailCellContainer {
  display: flex;
  align-items: center;
}

.AppDetailPage .CenterContent {
  font-size: 14px;
  color: #99A0A6;
  display: flex;
  flex-direction: column;
  margin-top: -4px;
  flex-grow: 1;
}

.AppDetailPage .OwnershipButton {
  box-sizing: border-box;
  margin-top: 8px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #4B5966;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  color: black;
}

.AppDetailPage .OwnershipButton.Purchased {
  letter-spacing: 0.32px;
  background-color: #5297E0;
  color: white;
  border: unset;
}

.AppDetailPage .CenterContent .Title,
.AppDetailPage .CenterContent .OwnershipButton {
  display: none;
}

.AppDetailPage .CenterContent .OwnershipButton {
  width: 150px;
  margin: 8px 0;
}

@media (min-width: 768px) {
  .AppDetailPage .AppIcon {
      margin-right: 12px;
      width: 128px;
      height: 128px;
  }

  .AppDetailPage .OwnershipButton,
  .AppDetailPage > .Title {
      display: none;
  }

  .AppDetailPage .CenterContent .Title,
  .AppDetailPage .CenterContent .OwnershipButton {
      display: unset;
  }
}
